import Utils from './utils';
import { ERROR_MESSAGES } from './constants';

export default {
  data() {
    return {
      inputItems: {
        category: '',
        licenses: [],
        prefecture: '',
        careerLength: '',
        company: '',
        name: '',
        tel: '',
        email: '',
        terms: true,
      },
      errorMessages: {
        category: '',
        license: '',
        prefecture: '',
        careerLength: '',
        company: '',
        name: '',
        tel: '',
        email: '',
        terms: '',
      },
      progress: 1,
      selectedPrefectureDone: false,
      selectedCareerLengthDone: false,
      params: [],
    };
  },
  mounted() {
    this.initProgressBarOnScroll();
    this.initParamsAndProgressBar();
  },
  computed: {
    isCategoryValid() {
      return this.inputItems.category !== '';
    },
    isLicensesValid() {
      return this.inputItems.licenses.length >= 1;
    },
    isPrefectureValid() {
      return this.inputItems.prefecture !== '';
    },
    isCareerLengthValid() {
      return this.inputItems.careerLength !== '';
    },
    isCompanyValid() {
      this.inputItems.company = Utils.zenkakuHankaku(this.inputItems.company);
      return this.inputItems.company.length > 1;
    },
    isNameValid() {
      this.inputItems.name = Utils.zenkakuHankaku(this.inputItems.name);
      return this.inputItems.name.length > 1;
    },
    isTelValid() {
      this.inputItems.tel = Utils.zenkakuHankaku(this.inputItems.tel);
      return /^\d{10,11}$/.test(this.inputItems.tel);
    },
    isEmailValid() {
      const VALID_EMAIL_REGEX = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i;
      this.inputItems.email = Utils.zenkakuHankaku(this.inputItems.email);
      return VALID_EMAIL_REGEX.test(this.inputItems.email);
    },
    isTermsValid() {
      return this.inputItems.terms;
    },
    isAllValid() {
      return (
        this.isCategoryValid &&
        this.isLicensesValid &&
        this.isPrefectureValid &&
        this.isCareerLengthValid &&
        this.isCompanyValid &&
        this.isNameValid &&
        this.isTelValid &&
        this.isEmailValid &&
        this.isTermsValid
      );
    },
  },
  methods: {
    // Parses the URL params
    parseParams() {
      const search = window.location.search.substring(1);
      if (search.length <= 0) return {};

      const params = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
      Object.keys(params).forEach((key) => {
        params[key] = params[key].split(',');
      });
      return params;
    },
    // Initialize progress bar on scroll
    initProgressBarOnScroll() {
      const progressBar = document.querySelector('.p-inquiry_ai__progress_bar_max');
      const parentElement = progressBar.parentNode;

      document.addEventListener('scroll', () => {
        if (window.scrollY >= 56) {
          progressBar.classList.add('-fixed');
          parentElement.style.marginTop = '35px';
        } else {
          progressBar.classList.remove('-fixed');
          parentElement.style.marginTop = '0';
        }
      });
    },
    // Initialize parameters and handle progress bar
    initParamsAndProgressBar() {
      this.params = this.parseParams();
      this.updateProgressBar();
    },
    // Handles button click
    handleButtonClick(current, next) {
      this.progress += 1;
      this.updateProgressBar();

      const nextDom = $(`.c-panel__item#${next}`);
      nextDom.fadeIn();
      $(`#${current} .next_btn`).hide();
      const nextPosition = nextDom.offset().top;
      $('html, body').animate({ scrollTop: nextPosition - 35 }, 700, 'swing');
    },
    // Handles category selection
    handleCategory(e) {
      this.setErrorMessages('category', this.isCategoryValid ? '' : ERROR_MESSAGES.AI_CATEGORY);
      this.toggleActiveClassForElements(document.querySelectorAll('.js-category-radio'), e.target.parentNode);
    },
    // Handles license selection
    handleLicenses(e) {
      this.setErrorMessages('license', this.isLicensesValid ? '' : ERROR_MESSAGES.AI_LICENSE);
      e.target.parentNode.classList.toggle('active');
    },
    // Handles prefecture selection
    selectPrefectureChangeHandler() {
      this.setErrorMessages('prefecture', this.isPrefectureValid ? '' : ERROR_MESSAGES.AI_PREFECTURE);
      this.selectedPrefectureDone = this.isPrefectureValid && !this.selectedPrefectureDone;
    },
    // Handles career length selection
    selectCareerLengthChangeHandler() {
      this.setErrorMessages('careerLength', this.isCareerLengthValid ? '' : ERROR_MESSAGES.AI_CAREERLENGTH);
      this.selectedCareerLengthDone = this.isCareerLengthValid && !this.selectedCareerLengthDone;
    },
    // Handles company input
    handleCompanyInput() {
      this.setErrorMessages('company', this.isCompanyValid ? '' : ERROR_MESSAGES.AI_COMPANYNAME);
    },
    // Handles name input
    handleNameInput() {
      this.setErrorMessages('name', this.isNameValid ? '' : ERROR_MESSAGES.AI_NAME);
    },
    // Handles telephone input
    handleTelInput() {
      this.setErrorMessages('tel', this.isTelValid ? '' : ERROR_MESSAGES.TEL);
    },
    // Handles email input
    handleEmailInput() {
      this.setErrorMessages('email', this.isEmailValid ? '' : ERROR_MESSAGES.EMAIL);
    },
    // Handles terms selection
    handleTerms() {
      this.setErrorMessages('terms', this.isTermsValid ? '' : ERROR_MESSAGES.TERMS);
    },
    // Handles form submission
    handleSubmit() {
      if (this.isAllValid) {
        const submitButton = document.querySelector('.inquiry_lp_ai_submit');
        submitButton.disabled = true;
        submitButton.textContent = '送信中...';

        document.querySelector('.inquiry_lp_ai_form').submit();

        const messageElement = document.querySelector('.inquiry_lp_ai_submitting_text');
        if (messageElement) {
          messageElement.textContent = 'AI診断中です。しばらくこのままお待ち下さい。';
        }
      } else {
        const errors = [];
        Object.keys(this.errorMessages).forEach((key) => {
          if (errors.length >= 1 || key === 'terms') { return; }
          if (this.errorMessages[key] !== '') { errors.push(Utils.camelToSnake(key)); }
        });
        if (errors.length > 0) {
          const nextPosition = $(`.c-panel__item#${errors[0]}`).offset().top;
          $('html, body').animate({ scrollTop: nextPosition - 40 }, 700, 'swing');
        }
      }
    },
    // Update the progress bar
    updateProgressBar() {
      const steps = 5;
      const width = this.progress / steps;
      const progressBar = document.querySelector('.p-inquiry_ai__progress_bar');
      progressBar.style.width = `${width * 100}%`;
      progressBar.textContent = `${this.progress}/${steps}`;
    },
    // Helper method to set error messages
    setErrorMessages(key, message) {
      this.errorMessages[key] = message;
    },
    // Helper method to toggle active class for elements
    toggleActiveClassForElements(elements, target) {
      elements.forEach((element) => {
        element.classList.remove('active');
      });
      target.classList.add('active');
    },
  },
};
